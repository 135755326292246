import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55')
];

export const server_loads = [];

export const dictionary = {
		"/": [12],
		"/(authenticated)/(has-debtor)/(hide-ng-frame)/airport-transfers/[id]": [13,[2,3,4]],
		"/approval/[id]": [47],
		"/auth0/signin.html": [49],
		"/auth0/signin": [48],
		"/authenticate": [50],
		"/(authenticated)/(has-debtor)/(hide-ng-frame)/booking-error": [14,[2,3,4]],
		"/bulk-booking/[pnr]": [51],
		"/(authenticated)/(has-debtor)/(show-ng-frame)/cars": [34,[2,3,8,9]],
		"/(authenticated)/(has-debtor)/(show-ng-frame)/cars/[id]": [35,[2,3,8,9]],
		"/(authenticated)/(has-debtor)/(hide-ng-frame)/confirmation": [15,[2,3,4]],
		"/(authenticated)/(has-debtor)/(show-ng-frame)/flights": [36,[2,3,8,10]],
		"/(authenticated)/(has-debtor)/(show-ng-frame)/flights/[id]": [37,[2,3,8,10]],
		"/hotel-suggestion/[pnr]/search/[id]": [52],
		"/(authenticated)/(has-debtor)/(show-ng-frame)/hotels": [38,[2,3,8,11]],
		"/(authenticated)/(has-debtor)/(show-ng-frame)/hotels/[id]": [39,[2,3,8,11]],
		"/(authenticated)/(has-debtor)/(hide-ng-frame)/international-flights": [16,[2,3,4,5]],
		"/(authenticated)/(has-debtor)/(hide-ng-frame)/international-flights/[itinerary_id]": [17,[2,3,4,5]],
		"/(authenticated)/(has-debtor)/(hide-ng-frame)/international-flights/[itinerary_id]/checkout": [18,[2,3,4,5]],
		"/(authenticated)/(has-debtor)/(hide-ng-frame)/international-flights/[itinerary_id]/checkout/add-segments": [19,[2,3,4,5]],
		"/(authenticated)/(has-debtor)/(hide-ng-frame)/itinerary": [20,[2,3,4,6]],
		"/(authenticated)/(has-debtor)/(hide-ng-frame)/itinerary/[itinerary_id]": [21,[2,3,4,6]],
		"/(authenticated)/(has-debtor)/(hide-ng-frame)/itinerary/[itinerary_id]/add-segments": [22,[2,3,4,6]],
		"/logged-out": [53],
		"/login": [54],
		"/(authenticated)/(has-debtor)/(hide-ng-frame)/manage-bookings": [23,[2,3,4]],
		"/(authenticated)/(has-debtor)/(hide-ng-frame)/manage-bookings/change/payment": [31,[2,3,4]],
		"/(authenticated)/(has-debtor)/(show-ng-frame)/manage-bookings/flights": [40,[2,3,8]],
		"/(authenticated)/(has-debtor)/(show-ng-frame)/manage-bookings/hotels/[id]": [41,[2,3,8]],
		"/(authenticated)/(has-debtor)/(hide-ng-frame)/manage-bookings/[pnr]": [24,[2,3,4]],
		"/(authenticated)/(has-debtor)/(hide-ng-frame)/manage-bookings/[pnr]/add-baggage": [25,[2,3,4]],
		"/(authenticated)/(has-debtor)/(hide-ng-frame)/manage-bookings/[pnr]/add-land-segments": [26,[2,3,4]],
		"/(authenticated)/(has-debtor)/(hide-ng-frame)/manage-bookings/[pnr]/add-land-segments/checkout": [27,[2,3,4]],
		"/(authenticated)/(has-debtor)/(hide-ng-frame)/manage-bookings/[pnr]/change": [28,[2,3,4,7]],
		"/(authenticated)/(has-debtor)/(hide-ng-frame)/manage-bookings/[pnr]/change/payment": [30,[2,3,4,7]],
		"/(authenticated)/(has-debtor)/(hide-ng-frame)/manage-bookings/[pnr]/change/[sector]": [29,[2,3,4,7]],
		"/(authenticated)/(has-debtor)/(show-ng-frame)/new": [42,[2,3,8]],
		"/(authenticated)/(has-debtor)/(show-ng-frame)/search": [43,[2,3,8]],
		"/(authenticated)/(has-debtor)/(show-ng-frame)/seatmaps/[itinerary]/[passenger]": [44,[2,3,8]],
		"/(authenticated)/(has-debtor)/(show-ng-frame)/seatmaps/[itinerary]/[passenger]/[active]/[flight]/[transport]": [45,[2,3,8]],
		"/(authenticated)/select-debtor": [46,[2]],
		"/sign-in": [55],
		"/(authenticated)/(has-debtor)/(hide-ng-frame)/taxis/[id]/[pnr]": [32,[2,3,4]],
		"/(authenticated)/(has-debtor)/(hide-ng-frame)/taxis/[id]/[pnr]/[taxiId]/confirmation": [33,[2,3,4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';