import { setupNewRelic, track as newRelicTrack } from '$lib/util/newRelic';
import { new_relic_enabled } from '$lib/http/core';

if (new_relic_enabled) {
	setupNewRelic();
}

export function handleError({ error, event, status, message }) {
	if (new_relic_enabled) {
		newRelicTrack.exception(error, { event, status, message });
	}
	return error;
}
