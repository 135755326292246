import { userInfo } from '$lib/store';
import { browser, version } from '$app/environment';
import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent';
import { new_relic_enabled, new_relic_application_Id, api_url } from '$lib/http/core';

export let newrelicBrowserAgent = null;
let currentUser = null;

export const track = {
	exception(telemetry, customProperties) {
		customProperties = {
			...customProperties,
			user: currentUser,
		};
		if (newrelicBrowserAgent === null) {
			console.log('[NewRelic][track:exception] %o %o', telemetry, customProperties);
			return;
		}
		return newrelicBrowserAgent.noticeError(telemetry, customProperties);
	},
};

export function setupNewRelic() {
	if (!browser) {
		return;
	}

	const applicationID = new_relic_application_Id;
	if (!applicationID) {
		console.warn('[NewRelic] no applicationID in environment settings');
		return;
	}

	const portalHostUrl = new URL(api_url);

	const options = {
		init: {
			session_replay: {
				enabled: true,
				block_selector: '',
				mask_text_selector: '*',
				sampling_rate: 10.0,
				error_sampling_rate: 100.0,
				mask_all_inputs: true,
				collect_fonts: true,
				inline_images: false,
				inline_stylesheet: true,
				mask_input_options: {},
			},
			distributed_tracing: {
				enabled: true,
				cors_use_newrelic_header: false,
				cors_use_tracecontext_headers: true,
				allowed_origins: [portalHostUrl.origin],
			},
			privacy: { cookies_enabled: true },
			ajax: { deny_list: ['bam.nr-data.net'] },
		},
		info: { beacon: 'bam.nr-data.net', errorBeacon: 'bam.nr-data.net', licenseKey: 'NRJS-548daa526257d90f2c0', applicationID, sa: 1 },
		loader_config: { accountID: '4475278', trustKey: '4475278', agentID: applicationID, licenseKey: 'NRJS-548daa526257d90f2c0', applicationID },
	};

	newrelicBrowserAgent = new BrowserAgent(options);

	newrelicBrowserAgent.setErrorHandler(function (err) {
		// Check for "isTrusted": false which is a synthetic
		// or third-party event that we don't want to log
		const isTrustedRegex = /"?isTrusted"?:\s?false/;
		if (isTrustedRegex.test(err?.message)) {
			return true;
		}

		// ignore "Script error" which are caused by external
		// scripts and contain no info in New Relic
		if (err?.message === 'Script error.') {
			return true;
		}

		// ignore random 404 requests that are appearing in new relic
		if (err?.text === 'Not Found' && err?.message.includes('&ptid')) {
			return true;
		}

		// ignore "Network Error" as this is usually
		// caused by fault on the client side
		if (err?.message?.includes('Network Error')) {
			return true;
		}

		return false;
	});

	newrelicBrowserAgent.setApplicationVersion(version);

	userInfo.subscribe((user) => {
		if (user) {
			newrelicBrowserAgent.setUserId(user.UserIdentifier);
			currentUser = {
				debtor: user.DebtorIdentifier,
				role: user.Role,
				roles: null,
			};
		} else {
			newrelicBrowserAgent.setUserId(null);
			currentUser = null;
		}
	});
}
